<template>
  <base-layout>
    <details-page-title
      title="Fleet Management"
      dropdown-text-button="Fleet Status"
      :details-id="id"
    >
      <!-- <status-dropdown
        :endpoint="getStatusEndpoint"
        method="PATCH"
        :statusCurrent="getStatus"
        statusKeyActivate="visibility"
        :statusValueActivate="true"
        statusKeyDeactivate="visibility"
        :statusValueDeactivate="false"
      /> -->
      <!-- App Icon -->
      <div class="flex align-center">
        <a class="w-12 h-12 mr-2">
          <img
            class="w-full h-full"
            src="@/assets/img/icons/playstore.png"
            alt=""
          />
        </a>
        <a class="w-12 h-12">
          <img
            class="w-full h-full"
            src="@/assets/img/icons/appStore.png"
            alt=""
          />
        </a>
      </div>
      <!-- /App Icon -->
    </details-page-title>

    <details-page-header
      :name="header.name"
      :badge="header.city.name"
      :country="header.country.name"
      :phone="header.phone_number"
      :email="header.email_address"
      :website="header.website_link"
      :tabs="tabs"
      :fleetCardInfo="summary"
      :symbol="symbol"
      :fleetRevenueSymbol="fleetRevenueSymbol"
      slotContainerClass="justify-between"
      rightSlotClass="justify-end"
    >
      <template v-slot:right>
        <div class="flex items-center">
          <div class="vehicle-container">
            <img
              src="@/assets/icon/scooter.png"
              class="object-cover w-full h-full p-4"
              alt=""
            />
          </div>
          <div class="flex flex-col ml-2">
            <span class="font-medium text-28px text-oDark">--</span>
            <span class="-mt-2 text-14px text-oDark">Scooters</span>
          </div>
        </div>
      </template>
    </details-page-header>

    <div class="min-h-screen">
      <router-view />
    </div>
  </base-layout>
</template>

<script>
// import { EventBus } from "@/utils/EventBus";
import { FleetConfig } from '@/config/FleetConfig'
import { StatusConfig } from '@/config/StatusConfig'

import BaseLayout from '@/views/shared/BaseLayout'

import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import DetailsPageHeader from '../../components/ui/DetailsPageHeader.vue'
// import StatusDropdown from "@/components/dropdown/StatusDropdown";
import { mapGetters } from 'vuex'

export default {
  name: 'ViewFleet',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    // StatusDropdown
  },
  data() {
    return {
      symbol: '',
      fleetRevenueSymbol: '',
      header: {
        address: '',
        city: {
          id: '',
          name: '',
        },
        country: {
          id: '',
          name: '',
        },
        summary: {
          total_revenue: 0,
          total_rider: 0,
          total_trip: 0,
          total_vehicle: 0,
        },
        created_at: '',
        default_financial_account: '',
        email_address: '',
        email_enabled: '',
        email_encryption: '',
        email_host: '',
        email_port: '',
        geofence: '',
        id: '',
        is_parking_required: '',
        last_refreshed_at: '',
        lock_min_battery: '',
        max_speed_limit: '',
        name: '',
        off_ride_location_update_frequency: '',
        on_ride_location_update_frequency: '',
        organization: '',
        private_code: '',
        private_code_refresh_time: '',
        registration_no: '',
        reservation_time: '',
        tax_id: '',
        updated_at: '',
        use_company_details: '',
        visibility: '',
        warehouse_lat: '',
        warehouse_lon: '',
        warehouse_name: '',
        zip_code: '',
      },
      tabs: [
        { text: 'Profile', href: this.getTabHref(`profile`) },
        { text: 'Summary', href: this.getTabHref(`summary`) },
        { text: 'Vehicles', href: this.getTabHref(`vehicles`) },
        { text: 'Riders', href: this.getTabHref(`riders`) },
        { text: 'Rides', href: this.getTabHref(`rides`) },
        { text: 'Payments', href: this.getTabHref(`payments`) },
        { text: 'Revenues', href: this.getTabHref(`revenues`) },
        // { text: 'Promotions', href: this.getTabHref(`promotions`) },
        { text: 'Reports', href: this.getTabHref(`reports`) },
      ],
      summary: {
        total_revenue: 0,
        total_rider: 0,
        total_trip: 0,
        total_vehicle: 0,
      },
    }
  },
  computed: {
    ...mapGetters('auth', ['organizationInfo']),
    id() {
      return this.$route.params.id
    },
    getStatus() {
      return this.header && this.header.visibility === true ? true : false
    },
    getStatusEndpoint() {
      return StatusConfig.api.fleet(this.id)
    },
  },
  async created() {
    await this.$http
      .get(FleetConfig.api.single(this.id))
      .then((res) => {
        console.log('fleet-header', res)
        this.header = res.data
        this.summary = res.data.summary

        if (this.summary.currency) {
          this.fleetRevenueSymbol = this.summary.currency.symbol
        } else {
          this.fleetRevenueSymbol = this.symbol
        }
      })
      .catch((err) => {
        console.log('fleet-header-err', err)
      })
  },
  mounted() {
    this.symbol = this.organizationInfo.default_currency
      ? this.organizationInfo.default_currency.symbol
      : '$'
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    getTabHref(path) {
      return `/fleets/${this.$route.params.id}/${path}`
    },
  },
}
</script>

<style lang="scss" scoped>
.vehicle-container {
  width: 60px;
  height: 60px;
  // margin: 0 13px 6px 0;
  // padding: 14px 16px 17px 17px;
  background-color: #fff310;
  border-radius: 100px;
}
</style>
